import React from 'react';

import './App.css';

import AudioGraph from './AudioGraph';

function App() {
  return (
    <AudioGraph></AudioGraph>
  );
}

export default App;